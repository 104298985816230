/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import '../environment/dev.js';
import { ensureTokenIsValid, secretToken } from './secrets.js';
import { getTrustedTypes, getTrustedTypesPolicy } from './trusted_types.js';
/**
 * Runtime implementation of `TrustedScriptURL` in browsers that don't support
 * it.
 */
class ResourceUrlImpl {
    constructor(url, token) {
        ensureTokenIsValid(token);
        this.privateDoNotAccessOrElseWrappedResourceUrl = url;
    }
    toString() {
        return this.privateDoNotAccessOrElseWrappedResourceUrl.toString();
    }
}
const GlobalTrustedScriptURL = typeof window !== 'undefined' ? window.TrustedScriptURL : undefined;
/**
 * Also exports the constructor so that instanceof checks work.
 */
export const TrustedResourceUrl = (GlobalTrustedScriptURL !== null && GlobalTrustedScriptURL !== void 0 ? GlobalTrustedScriptURL : ResourceUrlImpl);
/**
 * Builds a new `TrustedResourceUrl` from the given string, without
 * enforcing safety guarantees. It may cause side effects by creating a Trusted
 * Types policy. This shouldn't be exposed to application developers, and must
 * only be used as a step towards safe builders or safe constants.
 */
export function createResourceUrlInternal(url) {
    var _a;
    /** @noinline */
    const noinlineUrl = url;
    const trustedScriptURL = (_a = getTrustedTypesPolicy()) === null || _a === void 0 ? void 0 : _a.createScriptURL(noinlineUrl);
    return (trustedScriptURL !== null && trustedScriptURL !== void 0 ? trustedScriptURL : new ResourceUrlImpl(noinlineUrl, secretToken));
}
/**
 * Checks if the given value is a `TrustedResourceUrl` instance.
 */
export function isResourceUrl(value) {
    var _a;
    return (((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isScriptURL(value)) || value instanceof ResourceUrlImpl);
}
/**
 * Returns the value of the passed `TrustedResourceUrl` object while ensuring it
 * has the correct type.
 *
 * Returns a native `TrustedScriptURL` or a string if Trusted Types are
 * disabled.
 */
export function unwrapResourceUrl(value) {
    var _a;
    if ((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isScriptURL(value)) {
        return value;
    }
    else if (value instanceof ResourceUrlImpl) {
        return value.privateDoNotAccessOrElseWrappedResourceUrl;
    }
    else {
        let message = '';
        if (process.env.NODE_ENV !== 'production') {
            message = 'Unexpected type when unwrapping TrustedResourceUrl';
        }
        throw new Error(message);
    }
}
