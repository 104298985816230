/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import { unwrapResourceUrl, } from '../../internals/resource_url_impl.js';
import { unwrapScript } from '../../internals/script_impl.js';
import { getScriptNonce } from '../globals/window.js';
/** Propagates CSP nonce to dynamically created scripts. */
function setNonceForScriptElement(script) {
    const win = script.ownerDocument && script.ownerDocument.defaultView;
    const nonce = getScriptNonce(win || window);
    if (nonce) {
        script.setAttribute('nonce', nonce);
    }
}
/** Sets textContent from the given SafeScript. */
export function setTextContent(script, v, options) {
    script.textContent = unwrapScript(v);
    if (options === null || options === void 0 ? void 0 : options.omitNonce)
        return;
    setNonceForScriptElement(script);
}
/** Sets the Src attribute using a TrustedResourceUrl */
export function setSrc(script, v, options) {
    script.src = unwrapResourceUrl(v);
    if (options === null || options === void 0 ? void 0 : options.omitNonce)
        return;
    setNonceForScriptElement(script);
}
