/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import { unwrapUrlOrSanitize } from '../../builders/url_builders.js';
/**
 * open calls {@link Window.open} on the given {@link Window}, given a
 * target {@link Url}.
 */
export function open(win, url, target, features) {
    const sanitizedUrl = unwrapUrlOrSanitize(url);
    if (sanitizedUrl !== undefined) {
        return win.open(sanitizedUrl, target, features);
    }
    return null;
}
/** Returns CSP nonce, if set for any script tag. */
export function getScriptNonce(win) {
    return getNonceFor('script', win);
}
/** Returns CSP nonce, if set for any style tag. */
export function getStyleNonce(win) {
    return getNonceFor('style', win);
}
function getNonceFor(elementName, win) {
    var _a;
    const doc = win.document;
    // document.querySelector can be undefined in non-browser environments.
    const el = (_a = doc.querySelector) === null || _a === void 0 ? void 0 : _a.call(doc, `${elementName}[nonce]`);
    if (el) {
        // Try to get the nonce from the IDL property first, because browsers that
        // implement additional nonce protection features (currently only Chrome) to
        // prevent nonce stealing via CSS do not expose the nonce via attributes.
        // See https://github.com/whatwg/html/issues/2369
        return el['nonce'] || el.getAttribute('nonce') || '';
    }
    return '';
}
