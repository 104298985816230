/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @fileoverview
 * Functions which allow fetch() on resourceUrls to be
 * interpreted as SafeHtml or SafeScript.
 */
import { createHtmlInternal } from '../../internals/html_impl.js';
import { unwrapResourceUrl, } from '../../internals/resource_url_impl.js';
import { createScriptInternal } from '../../internals/script_impl.js';
import { createStyleSheetInternal, } from '../../internals/style_sheet_impl.js';
/**
 * IncorrectTypeError represents an error that can occur with {@link
 * fetchResourceUrl} when the server responds with a content type that would be
 * unsafe for the type of content requested.
 */
export class IncorrectContentTypeError extends Error {
    constructor(url, typeName, contentType) {
        super(`${url} was requested as a ${typeName}, but the response Content-Type, "${contentType} is not appropriate for this type of content.`);
        this.url = url;
        this.typeName = typeName;
        this.contentType = contentType;
    }
}
/**
 * This causes the compiler to better optimize `createHtmlInternal` calls, where
 * previously it was building and including the whole module without
 * tree-shaking.
 *
 * TODO(b/254093954) find out why this is and remove this workaround.
 */
function privatecreateHtmlInternal(html) {
    return createHtmlInternal(html);
}
/**
 * fetches a given {@link TrustedResourceUrl},
 * and returns a value which can be turned into a given safe type.
 */
export function fetchResourceUrl(u, init) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(unwrapResourceUrl(u).toString(), init);
        /**
         * the content type type of the response, excluding any MIME params
         */
        const mimeType = (_c = (_b = (_a = response.headers
            .get('Content-Type')) === null || _a === void 0 ? void 0 : _a.split(';', 2)) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.toLowerCase();
        return {
            html() {
                return __awaiter(this, void 0, void 0, function* () {
                    if (mimeType !== 'text/html') {
                        throw new IncorrectContentTypeError(response.url, 'SafeHtml', 'text/html');
                    }
                    const text = yield response.text();
                    return privatecreateHtmlInternal(text);
                });
            },
            script() {
                return __awaiter(this, void 0, void 0, function* () {
                    // see:
                    // https://html.spec.whatwg.org/multipage/scripting.html#scriptingLanguages
                    if (mimeType !== 'text/javascript' &&
                        mimeType !== 'application/javascript') {
                        throw new IncorrectContentTypeError(response.url, 'SafeScript', 'text/javascript');
                    }
                    const text = yield response.text();
                    return createScriptInternal(text);
                });
            },
            styleSheet() {
                return __awaiter(this, void 0, void 0, function* () {
                    if (mimeType !== 'text/css') {
                        throw new IncorrectContentTypeError(response.url, 'SafeStyleSheet', 'text/css');
                    }
                    const text = yield response.text();
                    return createStyleSheetInternal(text);
                });
            },
        };
    });
}
