/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import '../environment/dev.js';
import { ensureTokenIsValid, secretToken } from './secrets.js';
import { getTrustedTypes, getTrustedTypesPolicy } from './trusted_types.js';
/**
 * Runtime implementation of `TrustedScript` in browswers that don't support it.
 * script element.
 */
class ScriptImpl {
    constructor(script, token) {
        ensureTokenIsValid(token);
        this.privateDoNotAccessOrElseWrappedScript = script;
    }
    toString() {
        return this.privateDoNotAccessOrElseWrappedScript.toString();
    }
}
function createTrustedScriptOrPolyfill(script, trusted) {
    return (trusted !== null && trusted !== void 0 ? trusted : new ScriptImpl(script, secretToken));
}
const GlobalTrustedScript = typeof window !== 'undefined' ? window.TrustedScript : undefined;
/**
 * Also exports the constructor so that instanceof checks work.
 */
export const SafeScript = (GlobalTrustedScript !== null && GlobalTrustedScript !== void 0 ? GlobalTrustedScript : ScriptImpl);
/**
 * Builds a new `SafeScript` from the given string, without enforcing
 * safety guarantees. It may cause side effects by creating a Trusted Types
 * policy. This shouldn't be exposed to application developers, and must only be
 * used as a step towards safe builders or safe constants.
 */
export function createScriptInternal(script) {
    var _a;
    /** @noinline */
    const noinlineScript = script;
    return createTrustedScriptOrPolyfill(noinlineScript, (_a = getTrustedTypesPolicy()) === null || _a === void 0 ? void 0 : _a.createScript(noinlineScript));
}
/**
 * An empty `SafeScript` constant.
 * Unlike the functions above, using this will not create a policy.
 */
export const EMPTY_SCRIPT = /* #__PURE__ */ (() => { var _a; return createTrustedScriptOrPolyfill('', (_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.emptyScript); })();
/**
 * Checks if the given value is a `SafeScript` instance.
 */
export function isScript(value) {
    var _a;
    return ((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isScript(value)) || value instanceof ScriptImpl;
}
/**
 * Returns the value of the passed `SafeScript` object while ensuring it
 * has the correct type.
 *
 * Returns a native `TrustedScript` or a string if Trusted Types are disabled.
 */
export function unwrapScript(value) {
    var _a;
    if ((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isScript(value)) {
        return value;
    }
    else if (value instanceof ScriptImpl) {
        return value.privateDoNotAccessOrElseWrappedScript;
    }
    else {
        let message = '';
        if (process.env.NODE_ENV !== 'production') {
            message = 'Unexpected type when unwrapping SafeScript';
        }
        throw new Error(message);
    }
}
