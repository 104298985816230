/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
/* GENERATED CODE, DO NOT MODIFY */
import { pure } from '../../../internals/pure.js';
import { AttributePolicyAction, SanitizerTable, } from './sanitizer_table.js';
const ALLOWED_ELEMENTS = [
    'ARTICLE',
    'SECTION',
    'NAV',
    'ASIDE',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'HEADER',
    'FOOTER',
    'ADDRESS',
    'P',
    'HR',
    'PRE',
    'BLOCKQUOTE',
    'OL',
    'UL',
    'LH',
    'LI',
    'DL',
    'DT',
    'DD',
    'FIGURE',
    'FIGCAPTION',
    'MAIN',
    'DIV',
    'EM',
    'STRONG',
    'SMALL',
    'S',
    'CITE',
    'Q',
    'DFN',
    'ABBR',
    'RUBY',
    'RB',
    'RT',
    'RTC',
    'RP',
    'DATA',
    'TIME',
    'CODE',
    'VAR',
    'SAMP',
    'KBD',
    'SUB',
    'SUP',
    'I',
    'B',
    'U',
    'MARK',
    'BDI',
    'BDO',
    'SPAN',
    'BR',
    'WBR',
    'INS',
    'DEL',
    'PICTURE',
    'PARAM',
    'TRACK',
    'MAP',
    'TABLE',
    'CAPTION',
    'COLGROUP',
    'COL',
    'TBODY',
    'THEAD',
    'TFOOT',
    'TR',
    'TD',
    'TH',
    'SELECT',
    'DATALIST',
    'OPTGROUP',
    'OPTION',
    'OUTPUT',
    'PROGRESS',
    'METER',
    'FIELDSET',
    'LEGEND',
    'DETAILS',
    'SUMMARY',
    'MENU',
    'DIALOG',
    'SLOT',
    'CANVAS',
    'FONT',
    'CENTER',
    'ACRONYM',
    'BASEFONT',
    'BIG',
    'DIR',
    'HGROUP',
    'STRIKE',
    'TT',
];
const ELEMENT_POLICIES = [
    [
        'A',
        new Map([
            [
                'href',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_SANITIZE_URL,
                },
            ],
        ]),
    ],
    [
        'AREA',
        new Map([
            [
                'href',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_SANITIZE_URL,
                },
            ],
        ]),
    ],
    [
        'LINK',
        new Map([
            [
                'href',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY,
                    conditions: new Map([
                        [
                            'rel',
                            new Set([
                                'alternate',
                                'author',
                                'bookmark',
                                'canonical',
                                'cite',
                                'help',
                                'icon',
                                'license',
                                'next',
                                'prefetch',
                                'dns-prefetch',
                                'prerender',
                                'preconnect',
                                'preload',
                                'prev',
                                'search',
                                'subresource',
                            ]),
                        ],
                    ]),
                },
            ],
        ]),
    ],
    [
        'SOURCE',
        new Map([
            [
                'src',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY,
                },
            ],
            [
                'srcset',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY_FOR_SRCSET,
                },
            ],
        ]),
    ],
    [
        'IMG',
        new Map([
            [
                'src',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY,
                },
            ],
            [
                'srcset',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY_FOR_SRCSET,
                },
            ],
        ]),
    ],
    [
        'VIDEO',
        new Map([
            [
                'src',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY,
                },
            ],
        ]),
    ],
    [
        'AUDIO',
        new Map([
            [
                'src',
                {
                    policyAction: AttributePolicyAction.KEEP_AND_USE_RESOURCE_URL_POLICY,
                },
            ],
        ]),
    ],
];
const ALLOWED_GLOBAL_ATTRIBUTES = [
    'title',
    'aria-atomic',
    'aria-autocomplete',
    'aria-busy',
    'aria-checked',
    'aria-current',
    'aria-disabled',
    'aria-dropeffect',
    'aria-expanded',
    'aria-haspopup',
    'aria-hidden',
    'aria-invalid',
    'aria-label',
    'aria-level',
    'aria-live',
    'aria-multiline',
    'aria-multiselectable',
    'aria-orientation',
    'aria-posinset',
    'aria-pressed',
    'aria-readonly',
    'aria-relevant',
    'aria-required',
    'aria-selected',
    'aria-setsize',
    'aria-sort',
    'aria-valuemax',
    'aria-valuemin',
    'aria-valuenow',
    'aria-valuetext',
    'alt',
    'align',
    'autocapitalize',
    'autocomplete',
    'autocorrect',
    'autofocus',
    'autoplay',
    'bgcolor',
    'border',
    'cellpadding',
    'cellspacing',
    'checked',
    'color',
    'cols',
    'colspan',
    'controls',
    'datetime',
    'disabled',
    'download',
    'draggable',
    'enctype',
    'face',
    'formenctype',
    'frameborder',
    'height',
    'hreflang',
    'hidden',
    'ismap',
    'label',
    'lang',
    'loop',
    'max',
    'maxlength',
    'media',
    'minlength',
    'min',
    'multiple',
    'muted',
    'nonce',
    'open',
    'placeholder',
    'preload',
    'rel',
    'required',
    'reversed',
    'role',
    'rows',
    'rowspan',
    'selected',
    'shape',
    'size',
    'sizes',
    'slot',
    'span',
    'spellcheck',
    'start',
    'step',
    'summary',
    'translate',
    'type',
    'valign',
    'value',
    'width',
    'wrap',
    'itemscope',
    'itemtype',
    'itemid',
    'itemprop',
    'itemref',
];
const GLOBAL_ATTRIBUTE_POLICIES = [
    [
        'dir',
        {
            policyAction: AttributePolicyAction.KEEP_AND_NORMALIZE,
            conditions: /* #__PURE__ */ pure(() => {
                return new Map([
                    ['dir', new Set(['auto', 'ltr', 'rtl'])],
                ]);
            }),
        },
    ],
    [
        'async',
        {
            policyAction: AttributePolicyAction.KEEP_AND_NORMALIZE,
            conditions: /* #__PURE__ */ pure(() => {
                return new Map([
                    ['async', new Set(['async'])],
                ]);
            }),
        },
    ],
    [
        'cite',
        {
            policyAction: AttributePolicyAction.KEEP_AND_SANITIZE_URL,
        },
    ],
    [
        'loading',
        {
            policyAction: AttributePolicyAction.KEEP_AND_NORMALIZE,
            conditions: /* #__PURE__ */ pure(() => {
                return new Map([
                    ['loading', new Set(['eager', 'lazy'])],
                ]);
            }),
        },
    ],
    [
        'poster',
        {
            policyAction: AttributePolicyAction.KEEP_AND_SANITIZE_URL,
        },
    ],
    [
        'target',
        {
            policyAction: AttributePolicyAction.KEEP_AND_NORMALIZE,
            conditions: /* #__PURE__ */ pure(() => {
                return new Map([
                    ['target', new Set(['_self', '_blank'])],
                ]);
            }),
        },
    ],
];
/**
 * Sanitizer table for the default sanitizer configuration
 *
 */
export const DEFAULT_SANITIZER_TABLE = new SanitizerTable(new Set(ALLOWED_ELEMENTS), new Map(ELEMENT_POLICIES), new Set(ALLOWED_GLOBAL_ATTRIBUTES), new Map(GLOBAL_ATTRIBUTE_POLICIES));
/**
 * Sanitizer table used by the CSS sanitizer.
 */
export const CSS_SANITIZER_TABLE = new SanitizerTable(new Set(/* #__PURE__ */ pure(() => ALLOWED_ELEMENTS.concat(['STYLE']))), new Map(ELEMENT_POLICIES), 
// We allow id, name, and class because they can be used in CSS selectors.
// Shadow DOM ensures that those names don't clash with other names in the
// document.
new Set(
/* #__PURE__ */ pure(() => ALLOWED_GLOBAL_ATTRIBUTES.concat(['id', 'name', 'class']))), new Map(
/* #__PURE__ */ pure(() => GLOBAL_ATTRIBUTE_POLICIES.concat([
    [
        'style',
        { policyAction: AttributePolicyAction.KEEP_AND_SANITIZE_STYLE },
    ],
]))));
